<template>
  <validation-observer
    ref="reportConfigurationSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="reportConfigurationSaveModal"
      centered
      body-class="position-static"
      title="Cập nhật cấu hình"
      @show="onShow"
      @hide="onHide"
      :no-close-on-backdrop="true"
    >
      <b-form>
        <b-form-group label-for="name">
          <template v-slot:label>
            Tên cấu hình <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Tên cấu hình"
            rules="required"
          >
            <b-form-input
              id="code"
              v-model="targetConfiguration.name"
              name="name"
              placeholder="Nhập tên cấu hình"
              :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label="Giá trị"
          label-for="value"
        >
          <b-form-input
            id="code"
            v-model="targetConfiguration.value"
            name="name"
            placeholder="Nhập giá trị"
          />
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('menuSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BModal, BOverlay,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'
import { required } from '@validations'

export default {
  name: 'ReportConfigurationSave',
  directives: {
    Ripple,
  },
  components: {
    ValidationObserver,
    BModal,
    BFormInput,
    BOverlay,
    BButton,
    ValidationProvider,
    BForm,
    BFormGroup,
  },
  props: {
    configuration: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      targetConfiguration: {
        name: '',
        value: '',
      },
      required,
    }
  },
  methods: {
    ...mapActions({
      updateConfiguration: 'manageReport/updateConfiguration',
    }),
    onShow() {
      if (this.configuration) {
        this.targetConfiguration = { ...this.configuration }
      }
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    onHide() {
      this.$refs
        .reportConfigurationSaveFormRef
        .reset()
      this.targetConfiguration = {
        name: '',
        value: '',
      }
    },
    async onSave() {
      const valid = this.$refs
        .reportConfigurationSaveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = await this.updateConfiguration(this.targetConfiguration)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              this.$emit('succeed')
              this.$bvModal.hide('reportConfigurationSaveModal')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>
